import { Injectable } from "@angular/core";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { tap } from "rxjs/operators";

@Injectable({
    providedIn: 'root'
})
export class TmsService {
    constructor(private _http: HttpClient) { }

    // @deprecated
    getListTMS() {
        return this._http.get(`${environment.url}/tms/sa`)
    }

    createTMS({ name, serviceKey }: { name: string, serviceKey: string }) {
        return this._http.post(`${environment.url}/tms/sa`, { name })
    }

    createTMSByFTP({ id }) {
        return this._http.put(`${environment.url}/company/update-ftp/${id}/sa`, {})
    }

    getTypes(type: 'api' | 'ftp') {
        return this._http.get(`${environment.url}/tms/get/by-type/sa?type=${type}`).pipe(
            tap(console.log)
        )
    }
}